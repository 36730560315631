/* Header Styles */

header {
  background: none;
  width: calc(18% - 48px);
  position: sticky;
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 24px;
  text-align: center;
  padding: 48px 0 48px 48px;
}

.header-inner {
  height: calc(100% - 120px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  background: #ede9e6;
  box-shadow: rgba(75, 75, 112, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.logo-container {
  padding: 24px 24px 36px;
  margin-bottom: 12px;
  border-bottom: 1px solid #c2c8c8;
  color: #22282f;
  text-align: center;
}

.menu-divider {
  border-bottom: 1px solid #c2c8c8;
  margin: 12px 0;
}

.logo-container a {
  color: #fff;
  text-decoration: none;
}

.logo-container svg {
  width: 80%;
  height: auto;
  margin: 0 auto;
}

.logo-container svg path {
  fill: #22282f;
}

.main-menu,
.user-menu {
  color: #22282f;
}

.main-menu.logged-in {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #c2c8c8;
}

.main-menu .menu-item {
  padding: 6px 24px;
  transition: all 0.1s ease;
  background: none;
  display: block;
  color: #22282f;
  text-decoration: none;
}

.main-menu .menu-item:hover {
  background: rgba(255, 255, 255, 0.1);
}

.menu-item svg {
  width: 12px;
  vertical-align: middle;
  transition: all 0.2s ease;
  transform: scaleY(1);
}

.menu-item svg.open {
  transform: scaleY(-1);
}

.menu-item svg line {
  stroke: #22282f !important;
}

.level-two {
  padding-left: 12px;
}

.copyright {
  padding: 24px;
  color: #22282f;
  font-size: 13px;
}

.menu-user {
  padding: 6px 24px;
}

.mobile-menu {
  display: none;
}

.menus {
  overflow-y: scroll;
  scrollbar-color: #707275 #c2c8c8;
  scrollbar-width: thin;
  padding-right: 10px;
}

.top {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #c2c8c8;
}

::-webkit-scrollbar-thumb {
  background: #707275;
}

@media only screen and (max-width: 1500px) {

  header {
    width: calc(24% - 48px);
  }

}

@media only screen and (max-width: 1200px) {

  .mobile-menu {
    display: inline-flex;
    margin: auto 0;
  }

  .top {
    flex-direction: row;
  }

  header {
    height: max-content;
    margin-bottom: 0;
    padding: 0;
    z-index: 101;
    width: 100%;
  }

  .logo-container {
    padding: 0;
    margin: 0;
    border: 0;
  }

  .logo-container svg {
    height: 80px;
  }

  #Layer_1-2 {
    display: flex;
  }

  .header-inner {
    height: max-content;
  }

  .copyright {
    display: none;
  }

  .top {
    display: flex;
    justify-content: space-between;
  }

  .hide-mobile .menus {
    display: none;
  }

  .menus {
    position: absolute;
    background: #ede9e6;
    left: 0;
    z-index: 201;
    top: 110px;
    width: 100%;
  }

}