@font-face {
  font-family: 'westeinde';
  src: url('./fonts/westeinde-thin.woff');
}

@font-face {
  font-family: 'westeinde-italic';
  src: url('./fonts/westeinde-thinitalic.woff');
}

@font-face {
  font-family: 'westeinde-bold';
  src: url('./fonts/westeinde-bold.woff');
}

@font-face {
  font-family: 'westeinde-bold-italic';
  src: url('./fonts/westeinde-bolditalic.woff');
}

@font-face {
  font-family: 'AmenBold';
  src: url('./fonts/AMENDISPLAY-BOLDITALIC.OTF');
}

body {
  background: #d4d5d5;
  color: #22282f;
  font-family: 'westeinde';
  font-size: 16px;
}

strong {
  font-family: 'westeinde-bold';
  font-weight: normal;
}

.app-container {
  display: flex;
  width: 100%;
}

/* Utility Classes */

.mt-large {
  margin-top: 48px !important;
}

.mb-large {
  margin-bottom: 48px !important;
}

.mt-med {
  margin-top: 24px !important;
}

.mb-med {
  margin-bottom: 24px !important;
}

.ml-med {
  margin-left: 24px !important;
}

.mt-small {
  margin-top: 12px !important;
}

.mb-small {
  margin-bottom: 12px !important;
}

.mt-xs {
  margin-top: 6px !important;
}

.mb-xs {
  margin-bottom: 6px !important;
}

.gap-small {
  gap: 12px !important;
}

.gap-med {
  gap: 24px !important;
}

.gap-large {
  gap: 48px !important;
}

.smaller {
  font-size: .8em;
}

.fainter-one {
  opacity: 0.68;
}

.basic-header {
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
}

.linkish {
  cursor: pointer;
  opacity: 1.0;
}

.linkish:hover {
  opacity: 0.8;
}

.bold {
  font-family: 'westeinde-bold';
}

.block {
  display: block;
}

/** End Utility Classes **/

.page-container {
  width: 82vw;
}

.content-container {
  padding: 48px;
}

.top-bar {
  border-bottom: 1px solid #bcb9b9;
  height: 56px;
}

.box {
  background: #1d252c;
  padding: 24px;
}

.press-grid {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}

.press-box {
  flex: 1 0 18%;
  border-left: 6px solid #fff;
}

.press-box.active {
  border-left: 6px solid #fff;
}

a {
  color: #326f80;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  opacity: 0.85;
}

a button {
  cursor: pointer;
}

.page-header {
  padding-top: 4px;
  padding-bottom: 12px;
  margin-bottom: 48px;
  display: flex;
  justify-content: space-between;
}

.page-header .small-head-info {
  color: #7a8c92;
  align-self: end;
}

.page-header h1 {
  margin: 0;
  padding: 0;
  font-family: 'AmenBold';
  font-weight: normal;
  font-size: 60px;
}

h2,
h3,
h4 {
  font-family: 'westeinde-bold';
  font-weight: normal;
  margin: 0 0 24px 0;
}

.table-header {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  font-family: 'westeinde-bold';
  border-bottom: 1px solid #c2c8c8;
}

.table-row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c2c8c8;
  padding: 12px 0;
  margin-bottom: 2px;
  gap: 4px;
}

.table-header>.row-item:last-child,
.table-row>.row-item:last-child {
  text-align: right;
}

.form-label,
.standard-header {
  display: block;
  margin-bottom: 6px;
  font-family: 'westeinde-bold';
}

.form-help {
  font-size: 14px;
  margin-top: 6px;
}

.text-control,
.standard-data-block {
  margin-bottom: 24px;
}

.standard-data-block {
  max-width: 680px;
}

.text-control input,
.text-control select,
.text-control textarea,
.Input,
.p-FauxInput,
input,
.stray {
  min-width: calc(100% - 24px);
  max-width: calc(100% - 24px);
  padding: 8px 12px;
  border-radius: 2px;
  outline: none;
  font-family: 'westeinde';
  border: 1px solid #d4d5d5;
  background: #f1eeea;
  font-size: 15px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
}

input {
  padding: 6px 12px;
  border-radius: 2px;
  border: 2px solid #c2c8c8;
  outline: none;
  font-family: 'westeinde';
  background: #f1eeea;
}

input.table {
  min-width: 120px;
  width: 120px;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  box-shadow: 0 0 3px 2px #326f80;
}

.cancel {
  color: #e75353;
}

.text-control textarea {
  padding: 12px 12px;
  outline: none;
}

.binary-chooser {
  display: flex;
}

.binary-opt {
  padding: 4px 8px;
  background: #c1c5c5;
  cursor: pointer;
}

.binary-opt:first-child {
  border-radius: 6px 0 0 6px;
}

.binary-opt:last-child {
  border-radius: 0 6px 6px 0;
}

.binary-opt.chosen {
  background: #326f80;
  color: #fff;
}

.pill {
  padding: 6px 12px;
  background: #b4b9bb;
  display: inline-block;
  margin: 0 8px 8px 0;
  border-radius: 2px;
  font-size: 15px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.pill:focus {
  outline: none;
  box-shadow: 0 0 3px 2px #326f80;
}

.tag-column {
  padding: 2px 8px;
  background: #b4b9bb;
  display: inline-block;
  margin: 0 6px 4px 0;
  border-radius: 2px;
  font-size: 13px;
  cursor: pointer;
}

.tag-column.active,
.pill.active {
  background: #1a4a57;
  color: #fff;
}

.time-chooser {
  display: flex;
  max-width: 60%;
}

.time-chooser select {
  flex: 1;
  width: auto;
  min-width: 10px;
  margin-right: 4px;
}

.time-chooser span {
  padding: 4px;
}

.tag-adder {
  position: relative;
  margin-bottom: 12px;
}

.tag-list {
  position: absolute;
  left: 0;
  top: 29px;
  background: #fff;
  border: 1px solid #b4b9bb;
  border-radius: 2px;
}

.tag-list-item {
  padding: 8px;
  background: #fff;
  border-bottom: 1px solid #b4b9bb;
}

.tag-list-item:last-child {
  border-bottom: 0;
}

.loading-icon {
  height: 1em;
  width: 1em;
}

.tag-list-item:hover {
  cursor: pointer;
  background: #e3e9eb;
}

.filter-header {
  display: flex;
  justify-content: space-between;
}

.filters {
  display: flex;
}

.filters .text-control {
  display: flex;
  margin-left: 8px;
}

.filters .text-control .form-label {
  margin: auto 6px auto 0;
}

button.primary,
.checkout-form button {
  background: #326f80;
  border: 2px solid #326f80;
  color: #fcfcfc;
  padding: 8px 12px;
  font-size: 16px;
}

button.primary.bigger {
  font-size: 22px;
}

.small-print {
  font-size: 13px;
}

.checkout-form button {
  margin-top: 12px;
}

.secondary {
  background: none;
  border: 2px solid #326f80;
  color: #326f80;
  padding: 8px 12px;
  font-size: 16px;
}

.secondary:disabled {
  color: #c1c5c5;
  border-color: #c1c5c5;
  cursor: default !important;
}

button.primary:disabled {
  background: none;
  border-color: #c1c5c5;
  color: #222;
}

input::file-selector-button {
  background: none;
  border: 2px solid #326f80;
  color: #22282f;
  padding: 4px 6px;
  font-size: 14px;
}

.secondary--white {
  background: none;
  border: 2px solid #22282f;
  color: #22282f;
  padding: 8px 12px;
  font-size: 16px;
}

.orange {
  color: #326f80;
}

.pseudo-link {
  cursor: pointer;
}

.pseudo-link:hover {
  text-decoration: underline;
}

.status-pill {
  font-size: 13px;
  background: #d8d8d8;
  display: inline-block;
  padding: 2px 6px;
  border-radius: 4px;
  color: #fcfcfc;
}

.quote {
  background: #222;
}

.proofs {
  background: #326f80;
}

.proofs-border {
  border-left: 4px solid #326f80;
}

.production {
  background: #cc7213;
}

.production-border {
  border-left: 4px solid #cc7213;
}

.review {
  background: #e02e2e;
}

.red {
  color: #e02e2e;
}

.approved {
  background: #5f9e3c;
}

.approved-border {
  border-left: 4px solid #5f9e3c;
}

.shipped {
  background: #757575;
}

.shipped-border {
  border-left: 4px solid #757575;
}

.ordered {
  background: #ce477a;
}

.ordered-border {
  border-left: 4px solid #ce477a;
}

/* Kanban */

.kanban-board {
  display: flex;
  gap: 24px;
}

.kanban-column {
  flex: 1;
}

.kanban-column h3 {
  border-bottom: 1px solid #c2c8c8;
  padding-bottom: 6px;
}

.kanban-project {
  background: #ede9e6;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 12px;
  box-shadow: rgba(75, 75, 112, 0.25) 0px 4px 6px -2px, rgba(0, 0, 0, 0.3) 0px 2px 3px -3px;
}


/* Modal styles */

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.28);
  display: flex;
  z-index: 100;
}

.modal-content {
  max-height: 92%;
  height: calc(88% - 96px);
  width: 80%;
  margin: auto;
  padding: 48px;
  background: #ede9e6;
  box-shadow: rgba(75, 75, 112, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.flex {
  display: flex;
}

.flex-half {
  flex: 0.5;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.sb {
  justify-content: space-between;
}

.half {
  width: 48%
}

.modal-header {
  margin-bottom: 24px;
}

.modal-header h3 {
  margin: 0;
}

.step-tracker {
  width: 76%;
  vertical-align: baseline;
  align-self: baseline;
  gap: 4px;
}

.step-tracker--large {
  width: 100%;
}

.step-tracker .step {
  flex: 1;
  border-bottom: 6px solid #ddd;
  text-align: center;
  padding: 4px;
}

.step-tracker .step.active {
  border-bottom: 6px solid #222;
}

.att {
  display: inline-block;
  background: #e57353;
  color: #fcfcfc;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  font-size: 10px;
  font-family: westeinde-bold;
}

.homepage-grid {
  margin: 0 0 48px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
}

.homepage-grid-two {
  margin: -48px -48px 48px -48px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.homepage-grid__item--wide {
  grid-column: 1 / 5;
  background: #ede9e6;
  color: #22282f;
  position: relative;
  aspect-ratio: 11 / 8.5;
  display: flex;
  overflow: hidden;
  box-shadow: rgba(75, 75, 112, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.homepage-grid__item--wider {
  padding: 48px;
}

.homepage-grid__item--wider img {
  max-width: 80%;
}

.homepage-grid__text {
  margin: auto 96px;
  position: relative;
  z-index: 15;
}

.homepage-grid__text h2 {
  font-size: 8rem;
  margin: 12px 0 36px 0;
  font-family: AmenBold;
  line-height: 1em;
}

.homepage-grid__text p {
  font-size: 1.4rem;
  line-height: 1.4em;
  margin: 0;
}

.homepage-grid__gradient {
  height: 100%;
  position: absolute;
  background-image: linear-gradient(to left, rgba(237, 233, 230, 0), rgba(237, 233, 230, 1));
  width: 56%;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0.8;
}

.homepage-grid__noise {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  mix-blend-mode: hard-light;
  opacity: 0.3;
}

.homepage-grid__item--wide video {
  height: 100%;
  width: auto;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0.4;
  z-index: 1;
}

.homepage-grid__item {
  overflow: hidden;
  position: relative;
  background: #ede9e6;
  aspect-ratio: 1;
  box-shadow: rgba(75, 75, 112, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

}

.homepage-grid__item video {
  width: 100%;
  height: 100%;
}

.homepage-grid__item img {
  width: 100%;
  height: 100%;
}

.homepage_grid__greeting {
  color: #fcfcfc;
  padding: 12%;
}

.homepage-grid__item__inner-text {
  font-size: 18px;
  line-height: 1.4em;
}

/* Banner Experiment */

.callout {
  padding: 6px 12px;
  background: #326f80;
  transform: skew(20deg);
  display: inline-block;
}

.callout-text {
  transform: skew(-20deg);
}

.crop-mark {
  height: 36px;
  width: 36px;
  position: absolute;
  z-index: 100;
}

.bleed-mark {
  height: 24px;
  width: 24px;
  position: absolute;
  z-index: 100;
}

.crop-mark.top-left {
  left: 48px;
  top: 48px;
}

.crop-mark.top-left::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 68%;
  background: #22282f;
  bottom: 0;
  right: 0;
  top: 0;
}

.crop-mark.top-left::before {
  content: '';
  position: absolute;
  height: 1px;
  width: 68%;
  background: #22282f;
  bottom: 0;
  left: 0;
}

.crop-mark.top-right {
  right: 48px;
  top: 48px;
}

.crop-mark.top-right::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 68%;
  background: #22282f;
  top: 0;
  left: 0;
}

.crop-mark.top-right::before {
  content: '';
  position: absolute;
  height: 1px;
  width: 68%;
  background: #22282f;
  bottom: 0;
  right: 0;
}


.crop-mark.bottom-left {
  left: 48px;
  bottom: 48px;
}

.crop-mark.bottom-left::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 68%;
  background: #22282f;
  right: 0;
  bottom: 0;
}

.crop-mark.bottom-left::before {
  content: '';
  position: absolute;
  height: 1px;
  width: 68%;
  background: #22282f;
  top: 0;
  left: 0;
}

.crop-mark.bottom-right {
  right: 48px;
  bottom: 48px;
}

.crop-mark.bottom-right::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 68%;
  background: #22282f;
  left: 0;
  bottom: 0;
}

.crop-mark.bottom-right::before {
  content: '';
  position: absolute;
  height: 1px;
  width: 68%;
  background: #22282f;
  top: 0;
  right: 0;
}

.registration-bar {
  height: 24px;
  border: 2px solid #fcfcfc;
  position: absolute;
  top: 36px;
  display: flex;
  z-index: 12;
}

.bw {
  left: 192px;
}

.color {
  right: 192px;
}

.registration-step {
  width: 24px;
  height: 100%;
  border-right: 2px solid #fcfcfc;
}

.registration-step:last-child {
  border: 0;
}

/* Bleed */

.bleed-mark.top-left {
  left: 48px;
  top: 48px;
}

.bleed-mark.top-left::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 68%;
  background: #22282f;
  bottom: 0;
  right: 0;
  top: 0;
}

.bleed-mark.top-left::before {
  content: '';
  position: absolute;
  height: 1px;
  width: 68%;
  background: #22282f;
  bottom: 0;
  left: 0;
}

.bleed-mark.top-right {
  right: 48px;
  top: 48px;
}

.bleed-mark.top-right::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 68%;
  background: #22282f;
  top: 0;
  left: 0;
}

.bleed-mark.top-right::before {
  content: '';
  position: absolute;
  height: 1px;
  width: 68%;
  background: #22282f;
  bottom: 0;
  right: 0;
}


.bleed-mark.bottom-left {
  left: 48px;
  bottom: 48px;
}

.bleed-mark.bottom-left::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 68%;
  background: #22282f;
  right: 0;
  bottom: 0;
}

.bleed-mark.bottom-left::before {
  content: '';
  position: absolute;
  height: 1px;
  width: 68%;
  background: #22282f;
  top: 0;
  left: 0;
}

.bleed-mark.bottom-right {
  right: 48px;
  bottom: 48px;
}

.bleed-mark.bottom-right::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 68%;
  background: #22282f;
  left: 0;
  bottom: 0;
}

.bleed-mark.bottom-right::before {
  content: '';
  position: absolute;
  height: 1px;
  width: 68%;
  background: #22282f;
  top: 0;
  right: 0;
}

.home-pitch {
  line-height: 1.8em;
  font-size: 1.2vw;
  grid-row: 2 / 4;
  aspect-ratio: 3 / 2;
  padding: 2vw;
  grid-column: 1/4;
  background: #326f80;
  color: #ede9e6;
}

.home-pitch h3 {
  font-family: 'AmenBold';
  font-size: 2.4vw;
  line-height: 54px;
  margin-bottom: 2vw;
}

.print-shop {
  aspect-ratio: 11.5 / 8;
  background: #ede9e6;
  position: relative;
}

.print-shop h3 {
  font-family: 'AmenBold';
  font-size: 52px;
}

.printer {
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  background: #d4d5d5;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.printer.large {
  width: 20vw;
  height: 20vw;
}

.printer.largest {
  width: 24vw;
  height: 24vw;
}

.printer.medium {
  width: 16vw;
  height: 16vw;
}

.printer img {
  width: 100%;
}

.printer.top-left {
  top: 148px;
  left: 128px;
}

.printer.top-right {
  top: 128px;
  right: 48px;
}

.printer.bottom-left {
  bottom: 64px;
  left: 96px;
}

.printer.bottom-right {
  bottom: 48px;
  right: 96px;
}

.contact-form {
  background: #f1edea;
  padding: 48px;
}

.contact-form h3 {
  font-family: 'AmenBold';
  font-size: 48px;
}

.card-container {
  padding: 48px;
  background: #ede9e6;
  box-shadow: rgba(75, 75, 112, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.estimated-price {
  font-size: 22px;
  border-bottom: 4px solid #326f80;
  padding-bottom: 4px;
  display: inline-block;
}

.estimated-price .unit {
  font-size: 16px;
}

.deleteLink {
  color: #e75353;
}

.fileToggle {
  font-size: 12px;
  margin: 12px 0;
}

.kanban-dropzone {
  min-height: 140px;
  border: 2px dashed #c2c8c8;
  ;
  border-radius: 20px;
}

.kanban-dropzone:hover {
  border-color: #326f80;
}

@media only screen and (max-width: 1500px) {

  .page-container {
    width: 76vw;
  }

  .homepage-grid__text h2 {
    font-size: 5rem;
  }

}


@media only screen and (max-width: 1200px) {
  .app-container {
    flex-wrap: wrap;
  }

  .page-container {
    width: 100%;
  }

  .homepage-grid__item--wide {
    aspect-ratio: 8.5 / 11;
  }

  .home-pitch {
    font-size: 1.5vw;
    padding: 48px;
  }

  .home-pitch h3 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 900px) {
  .homepage-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .home-pitch {
    line-height: 1.8em;
    font-size: 18px;
    grid-row: 2 / 4;
    aspect-ratio: 3 / 2;
    padding: 48px;
    grid-column: 1/4;
    background: #326f80;
    color: #ede9e6;
  }

  .home-pitch h3 {
    font-family: 'AmenBold';
    font-size: 24px;
    line-height: 54px;
    margin-bottom: 48px;
  }

  .registration-bar,
  .crop-mark,
  .bleed-mark {
    visibility: hidden;
  }

  .content-container {
    padding: 12px;
  }

  .home-pitch {
    line-height: 1.8em;
    font-size: 18px;
    grid-row: 1/1;
    aspect-ratio: unset;
    padding: 36px;
    grid-column: 1/3;
    background: #326f80;
    color: #ede9e6;
  }

  .homepage-grid__text {
    margin: auto 24px;
  }

  .card-container {
    padding: 24px;
  }

  .homepage-grid__text p {
    line-height: 1.8em;
    font-size: 18px;
  }

  .page-header h1 {
    font-size: 48px;
  }
}

@media (max-width: 500px) {
  .homepage-grid__text h2 {
    font-size: 3.4rem;
  }


}