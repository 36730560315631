.display-message {
  position: fixed;
  z-index: 1000;
  padding: 36px 48px;
  font-size: 18px;
  top: 80px;
  left: calc(50% - 200px);
  background: #fcfcfc;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  width: 400px;
  opacity: 1.0;
  transition: all 0.4s ease;
  display: flex;
  justify-content: space-between;
}

.message-clear {
  width: 8px;
  height: 8px;
}

.message-clear svg {
  vertical-align: super;
}

.message-clear svg .cls-1 {
  fill: #46535d
}

.message-clear svg:hover .cls-1 {
  fill: #ce482d;
}

.message-label {
  font-family: 'westeinde-bold';
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 6px;
  color: #46535d;
  text-transform: uppercase;
}

.display-message.success {
  border-left: 8px solid #82dda7;
}

.display-message.warn {
  border-left: 8px solid #ce482d;
}

.display-message.info {
  border-left: 8px solid #151b23;
}

.display-message.invisible {
  opacity: 0;
}